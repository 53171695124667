import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from '../../common/services/basic.service';
import { API_VOUCHER_BATCH_LOAD_REPORT_DETAIL } from '../../connection.data';

@Injectable()
export class BatchLoadReportDetailService extends BasicService {
  constructor(http: HttpClient){
    super(http);
    this.baseUrl = API_VOUCHER_BATCH_LOAD_REPORT_DETAIL;
  }
}
