import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_VOUCHER_BATCH_LOAD } from '../../connection.data';

@Injectable()
export class VoucherBatchLoadService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_VOUCHER_BATCH_LOAD;
    }

    updateFile(item: any){
        return this.http.put<any>(`${this.baseUrl}/file/${item.get('id')}`, item);
    }

    retry(item: any){
      return this.http.put<any>(`${this.baseUrl}/file/retry/${item.id || item.get('id')}`, {});
    }

    toValid(item:any):Observable<string>{
        return this.http.put<any>(`${this.baseUrl}/valid/${item.id || item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    toDeleted(item:any):Observable<string>{
        return this.http.put<any>(`${this.baseUrl}/delete/${item.id || item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    validateBatch(item: any): Observable <any>{
        return this.http.post<any>(`${this.baseUrl}/validate`, item);
    }

    isProcessing(token: string): Observable <any>{
        return this.http.get<any>(`${this.baseUrl}/processing/${token}`);
    }
}
